<template>
  <div>
    <h1>Announcement</h1>
    <card>
      <AnnouncementList />
    </card>
  </div>
</template>

<script>
const AnnouncementList = () => import('./List')
const Card = () => import('@/components/app/Card')
export default {
  components: { AnnouncementList, Card },
}
</script>

<style>

</style>
